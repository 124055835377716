import * as React from 'react';
import Header from '../../../Header/Header';
import BlockHeader from '../../../UI/BlockHeader/BlockHeader';
import Icon from '../../../UI/Icon/Icon';
import ProjectAppLinks from '../../../UI/ProjectAppLinks/ProjectAppLinks';
import ProjectBlock from '../../../UI/ProjectBlock/ProjectBlock';
import ProjectFooter from '../../../UI/ProjectFooter/ProjectFooter';
import ProjectGoals from '../../../UI/ProjectGoals/ProjectGoals';
import ProjectIntro from '../../../UI/ProjectIntro/ProjectIntro';

import './Planeta.sass';
import PlanetaSlider from './PlanetaSlider/PlanetaSlider';

const challenges = [
  'Application’s sleek, modern look, designed for one-handed use',
  'Ability to buy tickets and 3D glasses directly through the app',
  'Integrated ApplePay, Masterpass, and Google Pay payment methods',
  'Scannable ticket in-app (no need for printing, etc)',
  'Reminders on upcoming movies and tickets',
  'Bonus program and free ticket on the user’s birthday',
];

class Planeta extends React.Component {

  render() {

    const info = [
      { label : 'Client', value : 'Planeta Kino IMAX' },
      { label : 'Partnership with', value : 'Spiilka Design Buro' },
      { label : 'Category', value : 'Mobile App' },
      { label : 'Year', value : '2019' },
    ];
    return (
      <main className='planeta'>
        <Header/>
        <ProjectIntro title='Planeta Kino'
                      description='Our mobile app for the Ukrainian Cinema network, Planeta Kino, enables users to browse movies available in theaters then purchase and redeem movie tickets all in one centralized location.'
                      heading='heading-case'
                      info={info}>
          <img src='../../../../images/projects/planeta/intro-phone.png'
               alt='Photo: smartphone with registration step'/>
        </ProjectIntro>
        <ProjectGoals challenges={challenges}/>
        <ProjectBlock className='planeta__slider'
                      heading='heading-num1'
                      title='Not your average showreel'
                      overlappedHeading
                      subtitle='Our extra-large movie card display gives users a more immersive, powerful movie browsing experience. Movies in the feed are color-coordinated as either In-Theatres and Coming Soon. '
                      centered={true}>
          <PlanetaSlider/>
        </ProjectBlock>

        <ProjectBlock className='planeta__filters'
                      heading='heading-num2'
                      title='Time-saving filters'
                      subtitle='Instead of wasting time sifting through all the movies, our smart filters feature allows users to find exactly what they’re looking for within seconds.'
                      image={{
                        src : '../../../../images/projects/planeta/smart-filters.png',
                        alt : 'Image: phones with filters',
                      }}/>
        <div className='project-block max-width planeta__favorites -image-first'>
          <BlockHeader
            title='Keep tabs on wish-list movies'
            subtitle='If users see a movie they’re interested in, they can simply press the heart to add to their favorites to be notified with a reminder when tickets go on sale.'
            orientation='vertical'
          >
            <Icon name='heading-num3' className='heading' raw/>
          </BlockHeader>
          <div className='project-block__content'>
            <img src='../../../../images/projects/planeta/favorites.png'
                 srcSet='../../../../images/projects/planeta/favorites@2x.png 2x'
                 alt='Image: phones with favourite films'/>
          </div>
        </div>
        <ProjectBlock className='planeta__zoom'
                      heading='heading-num4'
                      title='Zoom In for a Closer Look'
                      subtitle='With our seat picking feature, users can zoom in on the hall and select the exact seat they want. No more having to rush to get good seats!'
                      centered
                      overlappedHeading
                      image={{
                        src : '../../../../images/projects/planeta/sits-choosing.png',
                        alt : 'Image: phones with zoom in sits',
                      }}/>
        <ProjectBlock className='planeta__tickets'
                      heading='heading-num5'
                      title='Tickets at your fingertips'
                      subtitle='With the Planeta Kino app, users don’t have to worry about standing in line to purchase tickets. Just open the app and your ticket will be ready to be scanned.'
                      image={{
                        src : '../../../../images/projects/planeta/tickets.png',
                        alt : 'Image: phones with e-tickets',
                      }}/>
        <ProjectAppLinks icon='planeta-small'
                         title='Planeta Kino'
                         message='Download the free application and enjoy your cinema experience today. Available on the AppStore and GooglePlay.'
                         appStoreLink='https://apps.apple.com/ua/app/%D0%BF%D0%BB%D0%B0%D0%BD%D0%B5%D1%82%D0%B0-%D0%BA%D1%96%D0%BD%D0%BE/id593841464?l=en'
                         googlePlayLink='https://play.google.com/store/apps/details?id=com.planet.imax&hl=en'
                         className='planeta__links'
        />
        <ProjectFooter title='Viasat AAA-Shows'
                       className='planeta__footer'
                       link='/viasat'
                       imageSrc='../../../../images/projects/viasat-footer.png'
                       imageAlt='Image: polygonal shark'
        />
      </main>
    );
  }
}

export default Planeta;
