import React from 'react';
import Swiper from 'react-id-swiper';

import './PlanetaSlider.css';

const data = [
  { img : '/images/planeta-slider/maleficenta.jpg', typeText : 'Relux | Cinetch + | IMAX' },
  { img : '/images/planeta-slider/once-in-hollywood.jpg', typeText : 'Cinetch +' },
  { img : '/images/planeta-slider/le-mans-66.jpg', typeText : '4DX | Cinetch + | IMAX' },
  { img : '/images/planeta-slider/motherless-brooklyn.jpg', typeText : 'Relux | 4DX | Cinetch +' },
  {
    img      : '/images/planeta-slider/terminator-phatum.jpg',
    typeText : '4DX | Cinetch + | IMAX',
  },
  { img : '/images/planeta-slider/the-weather-child.jpg', typeText : 'Relux | 4DX | IMAX' },

  { img : '/images/planeta-slider/maleficenta.jpg', typeText : 'Relux | Cinetch + | IMAX' },
  { img : '/images/planeta-slider/once-in-hollywood.jpg', typeText : 'Cinetch +' },
  { img : '/images/planeta-slider/le-mans-66.jpg', typeText : '4DX | Cinetch + | IMAX' },
  { img : '/images/planeta-slider/motherless-brooklyn.jpg', typeText : 'Relux | 4DX | Cinetch +' },
  {
    img      : '/images/planeta-slider/terminator-phatum.jpg',
    typeText : '4DX | Cinetch + | IMAX',
  },
  { img : '/images/planeta-slider/the-weather-child.jpg', typeText : 'Relux | 4DX | IMAX' },

  { img : '/images/planeta-slider/maleficenta.jpg', typeText : 'Relux | Cinetch + | IMAX' },
  { img : '/images/planeta-slider/once-in-hollywood.jpg', typeText : 'Cinetch +' },
  { img : '/images/planeta-slider/le-mans-66.jpg', typeText : '4DX | Cinetch + | IMAX' },
  { img : '/images/planeta-slider/motherless-brooklyn.jpg', typeText : 'Relux | 4DX | Cinetch +' },
  {
    img      : '/images/planeta-slider/terminator-phatum.jpg',
    typeText : '4DX | Cinetch + | IMAX',
  },
  { img : '/images/planeta-slider/the-weather-child.jpg', typeText : 'Relux | 4DX | IMAX' }
];

const Carousel = ({ className, params }) => (
  <div className={['planeta-slider__carousel', className].filter(Boolean).join(' ')}>
    <Swiper {...params}>
      {data.map((item, i) => {
        return (
          <div key={i}>
            <div className='planeta-slider__carousel-item'>
              <div className='planeta-slider__carousel-item-image'
                   style={{ backgroundImage : `url('${item.img}')` }}/>
              <div className='planeta-slider__carousel-item-text'>{item.typeText}</div>
            </div>
          </div>
        );
      })}
    </Swiper>
  </div>
);

const isInRange = (value, range) => value > range[0] && value < range[1];
const interpolate = (value, rangeIn, rangeOut) => {
  if (!isInRange(value, rangeIn)) {
    return rangeOut[0];
  }
  return rangeOut[0] + ((value - rangeIn[0]) / (rangeIn[1] - rangeIn[0])) * (rangeOut[1] - rangeOut[0]);
};

const scaleMin = 0.9;
const scaleMax = 1.1;

const translateMin = -25;
const translateMax = 6;

const sliderParams = {
  autoplay : {
    delay : 2000,
  },

  slidesPerView        : 'auto',
  loop                 : true,
  loopAdditionalSlides : 15,
  centeredSlides       : true,
  spaceBetween         : 10,
  slideToClickedSlide  : true,
  watchSlidesProgress  : true,
  on                   : {
    progress : function(p) {
      var swiper = this;

      for (var i = 0; i < swiper.slides.length; i++) {

        let scale = scaleMin;
        let translate = translateMin;
        const { progress } = swiper.slides[i];

        if (isInRange(progress, [-1, 1])) {
          if (progress < 0) {
            scale = interpolate(progress, [-1, 0], [scaleMin, scaleMax]);
          } else {
            scale = interpolate(progress, [0, 1], [scaleMax, scaleMin]);
          }
          if (progress < 0) {
            translate = interpolate(progress, [-1, 0], [translateMin, translateMax]);
          } else {
            translate = interpolate(progress, [0, 1], [translateMax, translateMin]);
          }
        }

        const image = swiper.slides[i].querySelector('.planeta-slider__carousel-item-image');
        const text = swiper.slides[i].querySelector('.planeta-slider__carousel-item-text');

        image.style.transform = `scale(${scale})`;
        text.style.transform = `translateY(${translate}px)`;
      }
    },
  },
};

const PlanetaSlider = () => {
  // const [shouldRender, setShouldRender] = React.useState();
  //
  // React.useLayoutEffect(() => {
  //   setTimeout(() => {
  //     setShouldRender(true);
  //   }, 5000)
  // }, []);
  //
  // if (!shouldRender) {
  //   return null;
  // }

  return (
    <div className='planeta-slider'>
      <div className='planeta-slider__mask'/>
      <div className='planeta-slider__phone-bg'/>
      <Carousel params={sliderParams}/>
    </div>
  );
};

export default PlanetaSlider;
