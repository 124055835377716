import React, { Component } from 'react'
import PageWrapper from '../components/PageWrapper/PageWrapper'
import SEO from '../components/seo'
import Planeta from "../components/Pages/Projects/Planeta/Planeta"

class PlanetaPage extends Component {
  render() {
    return (
      <PageWrapper>
        <SEO
          title='Planeta Kino'
          description='Our mobile app for the Ukrainian Cinema network, Planeta Kino, enables users to browse movies available in theaters then purchase and redeem movie tickets all in one centralized location.'
        />
        <Planeta/>
      </PageWrapper>
    )
  }
}

export default PlanetaPage
